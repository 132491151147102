<template>
<div class="flex flex-column flex-auto">
    <div class="p-5">
        <div class="grid">
            <!-- Leaks over past days -->
            <div class="col-12">
                <div class="graph surface-card shadow-2 border-round p-3 mb-3 h-full">
                    <div class="">
                        <div class="flex align-items-center justify-content-between">
                            <span class="text-xl font-medium text-900">Leaks over past 30 days</span>
                        </div>
                        <div v-if='Object.keys(this.leaksOverTime).length === 0' class="mt-3">
                            <Skeleton width="20rem" height="4rem" borderRadius="16px"></Skeleton>
                        </div>
                        <div v-else class="mt-3">
                            <Chart type="line" :data="leaksOverTime" :options="leaksOverTimeOptions" class="h-full" :height="60"/>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Client leaks -->
            <div class="col-12">
                <div class="graph surface-card shadow-2 border-round p-3 mb-3 h-full">
                    <div class="flex align-items-center justify-content-between mb-3">
                        <span class="text-xl font-medium text-900">Leaks per client</span>
                    </div>
                    <DataTable :value="clientLeakData" scrollable scrollHeight="600px" v-model:expandedRows="expandedRows"
                               class="flex-shrink-0" resizableColumns columnResizeMode="fit">
                        <Column expander style="max-width: max-content"/>
                        <Column field="Name" header="Client Name"/>
                        <Column field="leakcount" header="Total Assets Leaked"/>
                        <Column field="prospects" header="Total Active Prospects"/>

                        <template #expansion="slotProps">
                            <div class="p-3 w-full">
                                <h3>Assets leaked for {{ slotProps.data.Name }}</h3>
                                <DataTable :value="slotProps.data.leaked" class="w-full">
                                    <Column field="id" header="Asset ID"></Column>
                                    <Column field="name" header="Asset Name"></Column>
                                    <Column field="exampleurl" header="Example URL"></Column>
                                    <Column value="Delete" style="max-width: 100px" bodyStyle="text-align:center">
                                        <template #body="{ data }">
                                            <div class="border-circle hover:text-900 hover:surface-100 cursor-pointer">
                                                <i class="pi pi-trash" @click="deleteProspect($event, data)"/>
                                            </div>
                                        </template>
                                    </Column>
                                </DataTable>

                                <h3>All prospects (excluding leaks) for {{ slotProps.data.Name }}</h3>
                                <DataTable :value="slotProps.data.others" class="w-full">
                                    <Column field="id" header="Asset ID"></Column>
                                    <Column field="name" header="Asset Name"></Column>
                                    <Column field="exampleurl" header="Example URL"></Column>
                                    <Column value="Delete" style="max-width: 100px" bodyStyle="text-align:center">
                                        <template #body="{ data }">
                                            <div class="border-circle hover:text-900 hover:surface-100 cursor-pointer">
                                                <i class="pi pi-trash" @click="deleteProspect($event, data)"/>
                                            </div>
                                        </template>
                                    </Column>
                                </DataTable>
                            </div>
                        </template>
                    </DataTable>
                </div>
            </div>

            <!-- Leak details -->
            <div class="col-12">
                <div class="graph surface-card shadow-2 border-round p-3 mb-3 h-full">
                    <div class="flex align-items-center justify-content-between mb-3">
                        <span class="text-xl font-medium text-900">Leak details</span>
                    </div>
                    <DataTable :value="leakDetails" scrollable scrollHeight="300px" resizableColumns columnResizeMode="fit">
                        <Column field="leaktimestamp" header="Timestamp" style="flex: 0 0 10rem"/>
                        <Column field="clientname" header="Client Name" style="flex: 0 0 15rem">
                            <template #body="{ data }">
                                <p class="ml-2 text-overflow-ellipsis" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ data.clientname }}</p>
                            </template>
                        </Column>
                        <Column field="assetname" header="Asset Name" style="flex: 0 0 15rem">
                            <template #body="{ data }">
                                <p class="ml-2 text-overflow-ellipsis" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ data.assetname }}</p>
                            </template>
                        </Column>
                        <Column field="indexer" header="Indexer" style="flex: 0 0 10rem">
                            <template #body="{ data }">
                                <p class="ml-2 text-overflow-ellipsis" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ data.indexer }}</p>
                            </template>
                        </Column>
                        <Column field="hash" header="Infohash">
                            <template #body="{ data }">
                                <p class="ml-2 text-overflow-ellipsis" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ data.hash }}</p>
                            </template>
                        </Column>
                        <Column field="title" header="Object Title">
                            <template #body="{ data }">
                                <p class="ml-2 text-overflow-ellipsis" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ data.title }}</p>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>

        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "Prospects",

    data: function() {
        return {
            leaksOverTime: [],
            leaksOverTimeOptions: {adaptive:true,maintainAspectRatio:true,plugins:{legend:{display:false,},},elements:{point:{pointRadius:0,},line:{tension:0.4,borderWidth:2,},},scales:{x:{ticks:{maxRotation:0}},y:{beginAtZero:true,}}},

            clientLeakData: [],
            leakDetails: [],
            expandedRows: [],
        }
    },

    mounted() {
        this.getLeakData();
        this.getClientLeakData();
        this.getLeakDetails();
    },

    methods: {
        getLeakData() {
            this.$factory.prospect.getLeakData()
                .then((response) => {
                    console.debug(response)
                    this.leaksOverTime = response;
                })
                .catch((error) => {
                    this.$emitter.emit('bad-toast', 'Failed to get leak data');
                    console.log(error);
                });
        },

        getClientLeakData() {
            this.$factory.prospect.getClientLeakData()
                .then((response) => {
                    console.debug(response)
                    this.clientLeakData = response;
                })
                .catch((error) => {
                    this.$emitter.emit('bad-toast', 'Failed to get leak data');
                    console.log(error);
                });
        },

        getLeakDetails() {
            this.$factory.prospect.getLeakDetails()
                .then((response) => {
                    console.debug(response)
                    this.leakDetails = response;
                })
                .catch((error) => {
                    this.$emitter.emit('bad-toast', 'Failed to get leak data');
                    console.log(error);
                });
        },

        deleteProspect(event, data) {
            console.debug(data)
            this.$confirm.require({
                target: event.currentTarget,
                message: 'Are you sure you want to delete this entry?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.$factory.prospect.deleteProspect(data.id).then(() => {
                        console.debug(data)
                        this.getClientLeakData()
                        this.$emitter.emit('good-toast', 'Deleted ' + data.name);
                    }).catch(error => {
                        console.error(error);
                        this.$emitter.emit('bad-toast', 'Failed to delete ' + data.domain);
                    })
                },
            });


        },
    },
}
</script>

<style scoped>

</style>
